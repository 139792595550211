import axios, { AxiosPromise } from 'axios';
import { authCookie } from '../utils/cookies';
import { AUTH_COOKIES } from '../constants/authCookies';
import { RequestType } from '../redux/api/interface';
import { refreshAccessToken } from './refreshToken';
import { STATUS_CODE } from '../constants/codeStatuses';

type makeRequestParams = {
  request: RequestType;
};

const {
  REACT_APP_RAK_CLIENT_ID: clientId,
  REACT_APP_RAK_ID_API: rakIdUrl,
  REACT_APP_API_PREFIX: baseUrl,
} = process.env;

const axiosInstance = ({ url }: { url: string }) =>
  axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'application/json',
    },
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sendApiRequest = ({ url = baseUrl || '' }: { url?: string }) => {
  const reqInstance = axiosInstance({ url });

  reqInstance.interceptors.request.use(
    (config) => {
      const accessToken = authCookie.getCookie(AUTH_COOKIES.accessToken);
      if (accessToken) {
        config.headers = {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        };
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  reqInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (
        error.response &&
        error.response.status === STATUS_CODE.UNAUTHORIZED
      ) {
        const refreshedAuthData = await refreshAccessToken();

        const config = error.config;
        const accessToken = refreshedAuthData?.data.accessToken as string;
        authCookie.setCookie(AUTH_COOKIES.accessToken, accessToken);
        config.headers.Authorization = `Bearer ${accessToken}`;
        config.headers.accept = '*/*';

        return await axios.request(config);
      }

      return Promise.reject(error);
    },
  );

  return reqInstance;
};

export const authApi = sendApiRequest({});

export default function makeRequest({
  request,
}: makeRequestParams): AxiosPromise {
  const { method, url, data, params } = request;

  return authApi({
    method,
    url,
    data,
    params,
  });
}

export { clientId, rakIdUrl, baseUrl };
