import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { validateState } from '../Login/utils';
import { getSessionParams } from '../../utils/session';

import { ROUTES } from '../../app/AppRoutes/routes';

import { useGetUserProfilePermissions } from '../../hooks/useGetUserProfilePermissions';

import authCookie from '../../utils/cookies/authCookie';
import { AUTH_COOKIES } from '../../constants/authCookies';
import { login } from '../../redux/authenticate/slice';

const { ROOT, DEVICES } = ROUTES;

const Callback = (): JSX.Element => {
  const dispatch = useDispatch();

  const { search } = useLocation();
  const navigate = useNavigate();

  const { checkUser } = useGetUserProfilePermissions();

  useEffect(() => {
    const { state, accessToken, refreshToken, idToken } =
      getSessionParams(search);

    if (
      process.env.NODE_ENV === 'development' &&
      accessToken &&
      refreshToken &&
      idToken
    ) {
      authCookie.setCookie(AUTH_COOKIES.accessToken, accessToken);
      authCookie.setCookie(AUTH_COOKIES.refreshToken, refreshToken);
      authCookie.setCookie(AUTH_COOKIES.idToken, idToken);
      dispatch(login(true));
    } else if (
      process.env.NODE_ENV === 'production' &&
      accessToken &&
      refreshToken &&
      idToken
    ) {
      authCookie.setCookie(AUTH_COOKIES.accessToken, accessToken);
      authCookie.setCookie(AUTH_COOKIES.refreshToken, refreshToken);
      authCookie.setCookie(AUTH_COOKIES.idToken, idToken);

      checkUser().then(() => navigate(DEVICES, { replace: true }));
    }

    if (!accessToken || !(state && validateState(state))) {
      navigate(ROOT, { replace: true });
    } else {
      checkUser().then(() => navigate(DEVICES, { replace: true }));
    }
  }, [search, navigate, dispatch]);

  return <></>;
};

export default Callback;
