import { API } from '../constants/api';
import { sendApiRequest, rakIdUrl, clientId } from './index';
import delay from '../utils/delay';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getUserProfileReq = async () => {
  await delay(200);
  const response = await sendApiRequest({ url: rakIdUrl }).get(
    `${API.RAK_ID.USER_PROFILE}/?clientId=${clientId}`,
  );

  return response.data;
};
